<template>
  <div>
    {{ getDuration () }}
  </div>
</template>
<script>
import { getDuration } from "@/utils/date";

export default {
  props: ['duration'],
  methods: {
    getDuration () {
      return getDuration(this.duration);
    }
  }
}
</script>